@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;

[data-sparta-container] .global-nav-module {
  .header__children .search-stub-child-container {
    @media #{$medium-up} {
      flex: 0 1 auto;
      margin: auto 2px;
    }

    @media #{$large-up} {
      margin: auto 2px 5px;
      min-width: 115px;
    }
  }

  .small-search {
    margin: -20px auto -15px !important; 
    max-width: 450px;
    min-height: 80px;
    padding: 0 10px;
  }

  .spa-input-box {
    z-index: auto;
  }
}


[data-sparta-wrapper='homepage'] [data-sparta-container='homepage'],
[data-sparta-wrapper='hp-assets'] [data-sparta-container='hp-assets'],
[data-sparta-wrapper='notice-hp-outage'] [data-sparta-container='notice-hp-outage'] {
  .header-module .main-nav-top-logo {
    #search {
      flex-basis: 26%;
      margin-right: 10px;
      max-width: 290px;
      min-height: 54px;
    }
  }

  .small-search {
    margin: -10px auto 0 !important; 

    @media #{$medium-up} {
      margin: -7px auto 0 !important;
    }

    .form {
      transform: translate(-50%,35%);
    }
  }

  .static-search .form {
    right: 19px;
  }
}




[data-sparta-container] .small-search, [data-sparta-container] .static-search {
  .form {
    width: 281px;
    display: flex;
    position: relative;
    right: 0;

    &--input {
      height: 40px !important; 
      border-radius: 2px !important;
      border-color: #787878 !important;
      padding-right: 66px !important;

      &:hover, &:focus {
        border-color: #0053c2 !important;
      }

      &::placeholder {
        color: #000000;
      }
    }

    &--search-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20x=%220%22%20y=%220%22%20width=%2224%22%20height=%2224%22%20viewBox=%220,0,24,24%22%3E%3Cpath%20fill=%22rgb(120,120,120)%22%20stroke=%22%22%20stroke-width=%22%22%20style=%22%22%20d=%22M16.1,14.3383085 C17,13.0447761 17.6,11.4527363 17.6,9.76119403 C17.6,5.68159204 13.9,2 9.8,2 C5.7,2 2,5.78109453 2,9.86069652 C2,13.9402985 5.7,17.6218905 9.8,17.6218905 C11.4,17.6218905 13.1,17.0248756 14.4,16.1293532 L14.4,16.1293532 L20.3,22 L22,20.3084577 C18.6,16.9253731 16.1,14.3383085 16.1,14.3383085 Z M9.8,15.5323383 C6.6,15.5323383 4,12.9452736 4,9.76119403 C4,6.57711443 6.6,4.08955224 9.8,4.08955224 C13,4.08955224 15.6,6.67661692 15.6,9.76119403 C15.6,12.8457711 12.9,15.5323383 9.8,15.5323383 Z%22%20/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent !important;
      border-radius: 0;
      position: absolute;
      height: 31px;
      right: 10px;
      top: 4px;

      &:hover, &:focus {
        background-color: transparent !important;
        box-shadow: 0 0 0 1px #0053c2;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20x=%220%22%20y=%220%22%20width=%2224%22%20height=%2224%22%20viewBox=%220,0,24,24%22%3E%3Cpath%20fill=%22rgb(0,83,194)%22%20stroke=%22%22%20stroke-width=%22%22%20style=%22%22%20d=%22M16.1,14.3383085 C17,13.0447761 17.6,11.4527363 17.6,9.76119403 C17.6,5.68159204 13.9,2 9.8,2 C5.7,2 2,5.78109453 2,9.86069652 C2,13.9402985 5.7,17.6218905 9.8,17.6218905 C11.4,17.6218905 13.1,17.0248756 14.4,16.1293532 L14.4,16.1293532 L20.3,22 L22,20.3084577 C18.6,16.9253731 16.1,14.3383085 16.1,14.3383085 Z M9.8,15.5323383 C6.6,15.5323383 4,12.9452736 4,9.76119403 C4,6.57711443 6.6,4.08955224 9.8,4.08955224 C13,4.08955224 15.6,6.67661692 15.6,9.76119403 C15.6,12.8457711 12.9,15.5323383 9.8,15.5323383 Z%22%20/%3E%3C/svg%3E");
      }

      &::before {
        content: ' ';
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: -17px;
        right: 0;
        display: block;
        border-left: 1px solid #d5d5d5;
      }
    }
  }
}

[data-sparta-container] .small-search .form {
  position: absolute;
  width: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);

  @media #{$medium-up} {
    max-width: 430px;
  }
}